import React from 'react';
import { Link } from 'gatsby'
import {  useForm } from "react-hook-form";
import {
  Container,
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Grid,
  Select,
  Modal
} from "@mui/material";
import './styles.scss'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  radius: '10px',
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const ModalMessage = ({modalIsOpen, setIsOpen }) => {

  const { reset } = useForm();
  
  const handleClose = () => {setIsOpen(false)};

  return (
    <>
    <Button 
      type="submit"
      size="small"
      variant="contained"
      color="primary"
      >
      Enviar
    </Button>
    <Modal
      open={modalIsOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid sx={style} container item lg={6} md={6} sm={10} xs={10}>
        <Box >
          <Typography variant="h3" color="primary.main" align="center" >
            Información enviada
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            La información ha sido enviada con éxito. En el transcurso de la semana nuestro equipo se comunicará con ustedes.
          </Typography>
          <div className='modal-button-container'>
            <Link to="/">
              <Button 
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                >
                Volver al inicio
              </Button>
            </Link>
          </div>
        </Box>
      </Grid>
    </Modal>
  </>
  );
}

export default ModalMessage;